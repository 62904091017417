import { Routes } from '@angular/router';
import { PagesEnum } from './models';

export const preRouteWithSessionUrl = ':venue/:table/:sessionUrl';
export const preRoute = ':venue/:table';

export const routes: Routes = [
  {
    path: `${preRoute}`,
    redirectTo: ({ params }) => `/${params['venue']}/${params['table']}/${PagesEnum.MENU}/${PagesEnum.MENU}`,
    pathMatch: 'full',
    data: { title: 'Food menu', preload: true },
  },
  {
    path: `${preRouteWithSessionUrl}`,
    redirectTo: ({ params }) => `/${params['venue']}/${params['table']}/${params['sessionUrl']}/${PagesEnum.MENU}`,
    pathMatch: 'full',
    data: { title: 'Food menu', preload: true },
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.ADMIN}`,
    loadComponent: () => import('./views/admin/admin.component').then(mod => mod.AdminViewComponent),
    data: { title: 'Admin', preload: true },
    // canActivate: [AdminGuard],
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.MENU}`,
    loadComponent: () => import('./views/venue-menu/venue-menu.component').then(mod => mod.VenueMenuViewComponent),
    data: { title: 'Food menu', preload: true },
    // canActivate: [AdminGuard],
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.ORDERS}`,
    children: [
      {
        path: '',
        loadComponent: () => import('./views/orders/suborders.component').then(mod => mod.SubordersViewComponent),
        data: { title: 'Orders', preload: true },
        // canActivate: [AdminGuard],
      },
      {
        path: 'payment',
        loadComponent: () => import('./views/orders/payment/payment.component').then(mod => mod.PaymentViewComponent),
        data: { title: 'Payment' },
        // canActivate: [AdminGuard],
      },
    ],
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.CART}`,
    loadComponent: () => import('./views/cart/cart.component').then(mod => mod.CartViewComponent),
    data: { title: 'Cart', preload: true },
    // canActivate: [AdminGuard],
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.WAITER}`,
    loadComponent: () => import('./views/waiter-commands/waiter-commands.component').then(mod => mod.WaiterCommandsViewComponent),
    data: { title: 'Waiter', preload: true },
    // canActivate: [AdminGuard],
  },
  {
    path: `${preRouteWithSessionUrl}/${PagesEnum.NOTIFICATIONS}`,
    loadComponent: () => import('./views/notifications/notifications.component').then(mod => mod.NotificationsViewComponent),
    data: { title: 'Notifications' },
    // canActivate: [AdminGuard],
  },
  {
    path: 'splash',
    loadComponent: () => import('./views/splash-screen/splash-screen.component').then(mod => mod.SplashScreenViewComponent),
    data: { title: 'Splash screen' },
  },
  { path: '**', redirectTo: 'splash', pathMatch: 'full' },
];
